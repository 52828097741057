
// GESTION CSS
require ('../stylesheets/application_web')

$('#suscrib').hide();
$("#suscrib").css("display", "none");
$('.suscrib').hide();
$(".suscrib").css("display", "none");

// NOTIFICACIONES
// 1) verifico que el navegador tenga soporte a las notificaciones 
if (!("Notification" in window)) {
}
else if (Notification.permission === "granted") {
  // console.log("El usuario contesto y otorgo los permisos para recibir notificaciones");
  $('.suscri').hide();
}
// Otherwise, we need to ask the user for permission
else if (Notification.permission === 'denied') {
  $('.suscri').hide();
}
